:root {
  --aubergine: #370028;
  --light-aubergine: #FF4DC3;
  --brc-red-more-color : #c62d10;
}

/* General */
body, html {height: 100%;}
html {position: relative; min-height: 100%;font-size:100%;}
body { min-width:320px; font-family: 'Open sans', sans-serif; font-weight: 300; font-size: 0.9rem; -webkit-font-smoothing: subpixel-antialiased}

a {color:#6A96AF;-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; transition: all .3s ease-in-out;}
a i:hover {text-decoration:none;}

body h1, body h2, body h3, body h4, body h5 { font-family: 'Oswald', sans-serif; }

body h1.page-title {background:#333; color:#fff; margin:15px; padding:5px 10px; text-transform: uppercase; font-style:normal; display:inline-block;}

.btn {font-family: 'Oswald', sans-serif; border-radius:0 !important; -moz-border-radius: 0 !important; -webkit-border-radius:0 !important;}
.btn-primary {background-color:#6A96AF; border-color:#6A96AF;}
.btn-primary:hover {background-color: rgb(129, 162, 181); border-color: rgb(129, 162, 181);}
.btn-primary.active, .btn-primary:active, .open>.dropdown-toggle.btn-primary {background-color: #5B8196;    border-color: #5B8196;}
.btn-primary.focus, .btn-primary:focus {background-color: #5B8196;    border-color: #5B8196;    outline: none;}
.btn.btn-brc-red {border:1px solid var(--aubergine); background-color:var(--aubergine); color:#fff;}
.btn.btn-brc-red:hover {border-color:var(--brc-red-more-color); background-color:var(--brc-red-more-color);}

.bg-home-grey { background:#f2f2f2 !important; }

.col-no-rp {padding-right:1px;}
.col-no-lp {padding-left:1px;}

h1,h2 {font-family: 'Open sans condensed', serif;}

/* Header */
.navbar {background-color:#333; border:0; /*   -webkit-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.75);    -moz-box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.75);    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.75)*/}
#topbar {background-color:#111; padding: 0; height: 33px; line-height: 29px;}
#topbar i.fa  {vertical-align: 0;padding-right:5px}
#topbar a, #topbar a:hover, #topbar a:active, #topbar a:visited {text-decoration: none; color:#fff;}
#topbar a {font-family: "Oswald";text-transform: uppercase; letter-spacing: 1px; font-size: .7rem;}
#topbar a.active, #topbar a:hover {color:var(--light-aubergine);}
#topbar button, #topbar button:hover, #topbar button:active, #topbar button:visited {
  text-decoration: none;
  color:white;
  background-color: #111;
  border: none;
}
#topbar button {font-family: "Oswald";text-transform: uppercase; letter-spacing: 1px; font-size: .7rem;}
#topbar button.active, #topbar button:hover {
  color:var(--light-aubergine);
  background-color: #111;
  border: none;
}

#languages_block_top {display:inline;}
#languages_block_top ul {margin:0;display:inline;}
#languages_block_top ul a {text-transform:uppercase;}

#header_right {min-height:40px;}
#header .navbar-header .row {/* height:80px;*/}
.navbar-inverse .btn-link {color:#fff;text-transform:uppercase; margin-top: 30px;}
.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus{text-decoration:none;}
.navbar-inverse .btn-link.btn-collapse {color:var(--light-aubergine);}
.navbar-inverse .btn-link.btn-collapse.collapsed {color:#fff;}

#header .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {max-height: inherit;}

/* editorial */
body#index h3 { font-size:1.9rem; }

body#index #center_column .container {min-height:0;}

html.wf-active .editorial-main-title { font-size:5rem; }

#menu .btn {height: auto;    padding: 5px 8px;    font-size: .8rem;    line-height: 1.2rem;    color: #111;    border: 0; min-width: 25px;  border-radius: 0;}

/* Group & private */
.normal { display: flex; flex-direction: row; flex-wrap: wrap; background-color: #dbdbdb; padding: 20px; }
.normal h2 { margin-top: 20px !important; }
.italic { font-style: italic; }
.centerImg { align-self: center; }
.centerContent { display: flex; flex-direction: column; align-items: center; }
.large{ width: 100%; }

@media (max-width:600px) {
  nav.navbar { margin-bottom: 0!important;}
  #header .navbar-header .row {  height: auto; padding:3px 0}
  #header .navbar-header .row > div:first-child { text-align: center;}
}

@media (max-width:1200px) {
  #columns_container {
    padding-top: 30px;
  }
}

@media screen and (min-width:1200px) {
  body {
    padding-top: 80px;
  }
}

a:focus, a:hover {
  color: lightgrey;
}

#topbar #accesportembarquement {font-weight: bold;}

div .padding-without-navbar {
  padding-top: 75px;
  margin-top: -75px;
}

.navbar-img-custom {
  float: left;
  height: 50px;
  padding: 20px -5px;
  font-size: 18px;
  line-height: 20px;
}

.onglet {
  font-size: 16px;
  height: 50px;
}

.onglet-reserver {
  font-size: 16px;
  height: 50px;
  background-color: #370028;
}

.navbar-inverse .navbar-nav>li>a:hover.onglet-reserver {
  background-color: #990066 ;
}

.encart{
  border: 2px lightgrey;
}
.encart .titre-encart{
  font-size: 2rem;
  border: 2px solid #370028;
  margin: 15px;
  padding: 5px 10px;
  font-style: normal;
  display: inline-block;
}

.encart .img-encart-large {
  width: auto;
  border: 2px solid #370028
}

.encart .encart-titre-descriptif{
  color: #370028;
  font-size: medium;
  font-weight: bold;
  text-decoration: underline #370028;
  text-underline-offset: 3px;
  text-underline-style: thick;
}

.contact-events{
  color: #370028;
  font-size: small;
  font-weight: bold;
}

.encart-description{
  border: 2px solid #370028;
  padding: 20px;
}

.choix{
  font-weight: bold;
}

.example-card {
  background-color: lightgrey;
  max-width: 400px;
}

.header-image-option {
  background-image: url('src/assets/img/repas.png');
  background-size: cover;
}

.example-header-image {
  background-image: url('src/assets/img/favicon.png');
  background-size: cover;
}

::ng-deep .mat-card.example-card {
  border: 10px solid #370028;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  margin: 7px;
}

::ng-deep .mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  padding-top: 5px;
}

.mat-list-base .mat-list-item .mat-line{
  word-wrap: break-word;
  white-space: pre-line!important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

#center_column .container {
  padding-top: 15px; margin-top: 10px;margin-bottom: 20px; min-height: 250px;
}

hr {
  margin-top: 2em;
  border: 3px solid #370028;
}

.reserver-bouton {
  float: right;
  margin-top: 15px;
}

@media(min-width: 1000px) {
  .bannerAccueil {
    background-image:url("../img/Boat-origin1200x627.JPG");
    height:400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .banner {
    background-image:url("../img/bassin3.jpg");
    height:400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
@media(max-width: 1000px) {
  .bannerAccueil {
    background-image:url("../img/Boat-origin1200x627.JPG");
    height:250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .banner {
    background-image:url("../img/bassin3.jpg");
    height:250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.font-classique {
  font-family: Akrobat-SemiBold, "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #5f5f5f;
}

.rouge-cccc {
  color: #370028;
}

.font-classique-title {
  font-weight: bold;
 }

.message_sent {
  color: #370028;
  font-weight: 600;
}

.message_error {
  color: Red;
  font-weight: 600;
}

.btn.btn-cccc-color {
  background-color: #370028;
  color: white;
}
.btn.btn-cccc-color:disabled {
  background-color: lightgrey;
  color: white;
}
.btn.btn-cccc-color:hover:enabled {
  background-color :var(--light-aubergine);
  color: white;
}

.form-control {-webkit-box-shadow: none;box-shadow: none;}

/* Plan d'accès */
#listing-harbours .harbour-item .harbour-map-container {width:100%; height:400px; z-index:10; overflow:hidden;}

/*Mentions Légales + CGV + Texte gestion*/
.rte > p {padding: 0 20px; clear: both; text-align: justify}
.rte > p > strong {color: #370028;}
.rte > p > i {
  padding-left: 14px;
  font-weight: bold;
}

.justify-content-center {
  justify-content: center;
}

.text-center {text-align: center;}
.text-right {text-align: right;}

/*Footer css*/
.footer { width:100%;}
.footer_top {background-color:#F2F2F2;}
.footer_top .container {padding:10px 15px}
.footer_top h4 {/*font-family: 'Open Sans Condensed', serif;*/ color:#6E6E6E;padding-bottom:15px}
.footer_top ul {  padding-left: 0;}
.footer_top li {list-style: none; border-top:thin dashed #ddd; padding:5px 0 5px 10px}
.footer_top li:hover {background-color:#e8e8e8; }
.footer_top li:hover a {color:#777; padding-left:10px}
.footer_top li a {color:#111;font-size: .9rem;}
.footer_top li a:hover {text-decoration:none}
.footer_top li {-webkit-transition: all .3s ease-in-out; -moz-transition: all .3s ease-in-out; transition: all .3s ease-in-out}
.footer_top .container > div:first-child li, .footer_top .container > div:last-child li {width: 50%;    float: left;}
.footer_bottom {background-color:#111;color:#9c9c9c;}

.anchor { padding-top: 80px; padding-bottom: 80px;}
.anchor-option { padding-top: 60px; padding-bottom: 60px;}

#page {
  position: relative;
  min-height: 100vh;
}

#columns_container {
  padding-bottom: 2.5rem;
}

#footer {
  position: absolute;
  width: 100%;
  height: 2.5rem;
}
